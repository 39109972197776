import React from "react";
import { graphql } from "gatsby"
import { Layout } from "../layouts/Site"
import { slug } from "../gatsby/service-pages.create"
import { PageWidth, Section } from "../components/Containers"
import { defaultStyles, PageHeading, SectionHeading } from "../components/Headings";
import { Image } from "../components/Image";
import { Content } from "../components/Content";
import { Testimonials } from "../components/Testimonial";
import { RichText } from "../components/RichText";
import { CallToAction } from "../components/CallToAction";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { Logo } from "../components/Logo";
import { join } from "../styles/styles";
import { Helmet } from "react-helmet";

const customStyles = {
  [BLOCKS.HEADING_1]: defaultStyles[BLOCKS.HEADING_2],
  [MARKS.UNDERLINE]: (text) => <span className='text-primary'>{text}</span>
}

export default function ServicePageTemplate({ data }) {
  const { site, servicePage, differentiator, defaultMetadata } = data
  servicePage.url = `${site.siteMetadata.url}${slug(servicePage.key)}`

  return <Layout title={servicePage.name}>
    <Helmet>
      <meta name="description" content={servicePage.metaDescription || defaultMetadata.metaDescription} />
    </Helmet>
    <PageWidth className='pt-header md:pt-header-md'>
      <Section>
        <PageHeading className="text-primary pt-8">{servicePage.name}</PageHeading>
      </Section>
      <Banner content={servicePage.banner} />
    </PageWidth>
    <PageWidth>
      <Section cols="2">
        <RichText body={servicePage.painpoints} styles={customStyles} />
        <RichText body={servicePage.benefits} styles={customStyles} />
      </Section>
      <CallToActionSection content={servicePage.tellMeMore} />
    </PageWidth>
    <TestimonialSection testimonials={servicePage.testimonials} />
    <ProofPointSection image={servicePage.processImage} skip={servicePage.testimonials} />
    <PageWidth className='px-0 py-5'>
      <ServiceDetails content={servicePage} className='px-5 xl:px-0'>
        <XodiacDifference content={differentiator} />
      </ServiceDetails>
    </PageWidth>
    <PageWidth>
      <CallToActionSection content={servicePage.callToAction} />
    </PageWidth>
  </Layout>
}

function TestimonialSection({ testimonials }) {
  return testimonials && <PageWidth className='bg-secondary2-200 text-page my-6'>
    <Section>
      <Testimonials testimonials={testimonials} />
    </Section>
  </PageWidth>
}

function ProofPointSection({ image, skip }) {
  return !skip && image && <PageWidth className='bg-secondary2-200 text-page my-6'>
    <Section>
      {customStyles[BLOCKS.HEADING_1](null, <>{image.title}</>)}
      <Image image={image} className='mx-auto md:max-w-2xl'/>
    </Section>
  </PageWidth>
}

function XodiacDifference({ content }) {
  return content && <div className="bg-primary text-page p-6">
    <Logo type='icon' className='float-right' color='white' size='1.3em'/>
    <RichText body={content.body} styles={customStyles} />
  </div>;
}

function ServiceDetails({ content, className, children }) {
  return content && <>
    <Section className={className}>
      {defaultStyles[BLOCKS.HEADING_1](null, <>The <span className='font-title'>xodiac</span> approach</>)}
      <RichText body={content.overview} styles={customStyles} />
    </Section>
    <Section cols="2" className="md:gap-0 md:divide-x md:divide-solid">
      <div>
        <RichText body={content.details1} className={join(className, 'py-6 md:pr-6')} styles={customStyles} />
        {children}
      </div>
      <div className={join(className, 'py-6 md:pl-6 xl:pl-5')}>
        <RichText body={content.details2} styles={customStyles} />
        <RichText body={content.details3} styles={customStyles} />
      </div>
    </Section>
  </>;
}

function Banner({ content }) {
  let leftAligned = false

  const styles = {
    ...customStyles,
    [BLOCKS.UL_LIST]: (node, children) => <div className="py-6">{children}</div>,
    [BLOCKS.LIST_ITEM]: bullet,
  }

  return content && <Section cols='auto' className='pt-10'>
    <Content content={content} richTextStyles={styles} className='self-center' hideBackgroundImage/>
    <Image image={content.image} className='py-6' />
  </Section>

  function bullet(node, children) {
    leftAligned = !leftAligned
    return <p className={leftAligned ? 'text-left' : 'text-right'}>{children}</p>
  }

}

function CallToActionSection({ content }) {
  return content && <Section className='pt-10 md:flex'>
    <div className='content-center md:grow'>
      <RichText body={content.body} styles={customStyles}/>
    </div>
    <CallToAction className='pb-4 text-center' action={content.callToAction} styles={{color: 'text-page', bgColor: 'bg-secondary', text: 'md:text-lg'}} />
  </Section>
}

export const query = graphql`query($key: String!) {
  site: site {
    siteMetadata {
      url
    }
  }
  differentiator: contentfulSiteContent(key: {eq: "xodiac-difference"}) {
    ...Content
  }
  servicePage: contentfulSiteServicePage(key: {eq: $key}) {
    key
    name
    metaDescription

    banner {
      ...Content
    }

    testimonials {
      ...Testimonial
    }
    
    callToAction {
      ...Content
    }
    
    overview {
      raw
    }

    details1 {
      raw
    }

    details2 {
      raw
    }

    details3 {
      raw
    }

    painpoints {
      raw
    }
    
    processImage {
      ...ImageContent
    }

    benefits {
      raw
    }

    tellMeMore {
      ...Content
    }   
  }
  defaultMetadata: contentfulSiteContentList(key: {eq: "index"}) {
    metaDescription
  } 
}`