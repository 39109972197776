import React from "react"
import { RichText } from "./RichText"
import { PageHeading, testimonialStyles } from "./Headings"
import { graphql } from "gatsby"
import { Carousel } from "./Containers"
import { join } from "../styles/styles"

export function Testimonial({ testimonial, className }) {
  return <div className={className}>
    <RichText body={testimonial.quote} styles={testimonialStyles}/>
    <div className='text-right'>&mdash; {testimonial.source}</div>
  </div>
}

export function Testimonials({testimonials, color = 'text-inherit', bgColor = 'bg-inherit', hideTitle = false}) {
  if (testimonials.__typename === "ContentfulSiteTestimonial")
    return <SingleTestimonial testimonial={testimonials} />

  if (testimonials.__typename === "ContentfulSiteContentList")
    return <>
      <TestimonialTitle hideTitle={hideTitle}>{testimonials.name}</TestimonialTitle>
      <TestimonialList testimonials={testimonials.contents} />
    </>

  return <TestimonialList testimonials={testimonials} />

  function SingleTestimonial({testimonial}) {
    return <TestimonialContainer>
      <div className={join(bgColor, color, "w-full rounded-xl")}>
        <StyledTestimonial testimonial={testimonial} />
      </div>
    </TestimonialContainer>
  }

  function TestimonialList({testimonials}) {
    if (testimonials.length === 1)
      return <SingleTestimonial testimonial={testimonials[0]} />

    return <TestimonialContainer>
      <Carousel className='w-full' trackClassName={ join(bgColor, color, 'rounded-xl') } hideButtons>
        {testimonials.map(testimonial => <div className='h-full flex items-center'><StyledTestimonial testimonial={testimonial} stretch /></div>)}
      </Carousel>
    </TestimonialContainer>
  }

  function TestimonialTitle({hideTitle, children}) {
    return hideTitle ? null : <PageHeading>{children}</PageHeading>
  }

  function TestimonialContainer({children}) {
    return <div className='flex justify-center'>{children}</div>;
  }

  function StyledTestimonial({testimonial, stretch}) {
    return <Testimonial testimonial={testimonial} className={join(stretch ? 'flex-grow' : null, 'p-10 flex flex-col content-center')} />
  }
}

export const query = graphql`
  fragment Testimonial on ContentfulSiteTestimonial {
    __typename
    title
    quote {
      raw
    }
    source
  }
`