const path = require('path')

async function createPagesForServicePages(graphql, createPage) {
  const { data } = await graphql(`query {
              servicePages: allContentfulSiteServicePage {
                nodes {
                  id
                  contentful_id
                  key
                }
              }
            }
        `)

  createServicePages(data.servicePages.nodes)

  function createServicePages(servicePages) {
    servicePages.forEach(servicePage => {
      console.log(`Creating service page ${servicePage.key} (${servicePage.id}, ${servicePage.contentful_id})`)
      const servicePageSlug = slug(servicePage.key)
      createPage({
        path: servicePageSlug,
        component: path.resolve("./src/templates/service-page.template.js"),
        context: { key: servicePage.key }
      })
    })
  }
}

function slug(key) {
  return append('/services', append(key))

  function append(slug, part) {
    return part ? `${slug}/${part}` : slug
  }
}

module.exports = {
  createPagesForServicePages,
  slug
}